const nav = {
  open: function() {
    $(".hamburger").addClass("open");
    $(".sidenav").addClass("active");
    $(".page").addClass("open-nav");
    // $('.sidebar').fadeIn();
  },
  close: function() {
    $(".hamburger").removeClass("open");
    $(".sidenav").removeClass("active");
    $(".page").removeClass("open-nav");
    // $('.sidebar').fadeOut();
  },
  expandDropdown: function(e) {
    const navItem = $(e.currentTarget).closest(".company");

    if (navItem.hasClass("active")) {
      navItem.removeClass("active");
    } else {
      $(".sidenav .company").removeClass("active");
      navItem.addClass("active");
    }
  }
};

$(".hamburger").on("click", function(e) {
  if ($(e.currentTarget).hasClass("open")) {
    nav.close();
  } else {
    nav.open();
  }
});

$(".sidenav .nav-arrow, .sidenav .nav-arrow").bind("click", nav.expandDropdown);
// $(".sidenav .nav-arrow, .sidenav a.company-title").bind("click", nav.expandDropdown);
